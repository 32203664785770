<template>
        <!-- Footer  -->
        <div class="row">
          <div class="col text-center my-4"> 
            <a class="icon px-3" href="https://www.facebook.com/profile.php?id=100087709973763" target="_blank"><font-awesome-icon :icon="['fab', 'facebook-f']" size="2x"/></a>
            <a class="icon px-3" href="https://www.instagram.com/elf.hanno/" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']" size="2x"/></a>
          </div>
      </div>
  </template>
  
  <script>

  
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  li a {
    color: rgb(153, 0, 0);
    font-weight: bold;
  }
  .icon{
    color: rgb(153, 0, 0);
    font-weight: bold;
  }

  </style>