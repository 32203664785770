import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import AboutView from '../views/AboutView.vue';
import CalendarView from '../views/CalendarView.vue';
import TeamView from '../views/TeamView.vue';
import DayView from '../views/DayView.vue';
import SportView from '../views/SportView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: CalendarView
  },
  {
    path: '/calendar/:id',
    name: 'DayView',
    component: DayView,
    props: (route) => ({id: Number(route.params.id)})
  },
  {
    path: '/team',
    name: 'Team',
    component: TeamView
  },
  {
  path: '/sport',
  name: 'Sport',
  component: SportView
},
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
