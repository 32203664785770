<template>
  <div class="highlight">
    <h1></h1>

    <div class="row">
      
      <div class="col-md-6 mb-4">
        <div class="p-4 border rounded">

            <br>


                  <p>Hier sollen unsere motivierten FITMITHANNO-PionierInnen geehrt werden, die uns mit Hingabe, Motivation und Körpereinsatz als VorturnerInnen einen Teil ihrer wertvollen Zeit geschenkt haben!</p>

                  <p>Ein starkes High Five geht außerdem an das großartige Team von <a href="https://crossfitvienna.at/" target="_blank">CROSSFIT VIENNA</a>, das uns bei Durchführung unseres Adventspektakels in ihren Räumlichkeiten unterstützt.</p>


                  <br>

                  <h3 class="pb-4">Dankeschön </h3>

                  <br>

                  <p>Möchtest auch du den Geist der Vorweihnacht mit uns teilen? Lass uns dein Sport-Advent Video zukommen!</p>
                  <p><span class="font-weight-bold mr-2">Email: </span><a href="">feedback@fitmithanno.fun</a></p>

        </div>
      </div>

      <div class="col-md-6 mb-4">
        <bubble-component></bubble-component>
    </div>

    </div>


    <p></p>
    
    <div class="container-secret ">
      <!-- <section class="secret m-4 p-4 border rounded ">

          
          <p class="text-warn">Hier sollen unsere motivierten Fit-mit-Hanno Pioniere erwähnt werden, die uns geduldig mit Geduld, Motivation und Körpereinsatz als VorturnerInnen einen Teil ihrer wertvollen Zeit geschenkt haben!
              <br><br>
              <a href="https://www.instagram.com/jaumeyr/" target="_blank">Jackie, </a>  <a href="
              https://www.instagram.com/hannerlgrotz/" target="_blank">Hanni, </a>   <a href="
              https://www.instagram.com/carmenkulterer/" target="_blank">Carmen, </a>  <a href="
              https://www.instagram.com/ninaslchnr/" target="_blank">Nina, </a>  <a href="
              https://www.instagram.com/karin_project_you/" target="_blank">Karin, </a>  
              <a href="
              https://www.instagram.com/claudia.zazz/" target="_blank">Claudia, </a>  
              <a href="
              https://www.instagram.com/basti.rieder/" target="_blank">Basti, </a>  <a href="
              https://www.instagram.com/langatium/" target="_blank">Heinz, </a>
              <a href="https://www.instagram.com/emi__holy/" target="_blank">Emina, </a>
              <a href="https://www.instagram.com/babra_ann/" target="_blank">Babsi, </a>
              <a href="
              https://www.instagram.com/sandrakammermayer/" target="_blank">Sandra, </a> <a href="
              https://www.instagram.com/marcelinazappe/" target="_blank">Marcelina. </a>

          </p>

          <h2 class="font-weight-bold header-warn mb-4 mt-4">Danke...</h2>



      </section> -->
  </div>

 

  <!-- <div class="container-secret ">
    <section class="secret m-4 p-4 border rounded ">

        
        <p class="text-warn"> Hier vielleicht eine Gallery of some making of pics... und die fotos der 2 Gewinner mit Originalfoto

        </p>

        <h2 class="font-weight-bold header-warn mb-4 mt-4">Fotogallery...</h2>

    

    </section>
</div> -->


  <footer-component></footer-component>
  
  </div>
</template>



<script>

import BubbleComponent from '../components/BubbleComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';

export default {
  name: 'Special_Thanks',
  components: {
    BubbleComponent,
    FooterComponent
  },
}
</script>
