<template>
  <!-- <HeaderComponent></HeaderComponent> -->
    <div class="calendar">

      <DayComponent :id="id"></DayComponent>
      <footer-component></footer-component>
    </div>
  </template>
  
  <script>
  import DayComponent from '@/components/DayComponent.vue';
  //import HeaderComponent from '@/components/HeaderComponent.vue';
  import FooterComponent from '@/components/FooterComponent.vue';

  export default {
    name: 'DayView',
    props: {
            id: {
                type: Number, // or Number if you know `id` is numeric
                required: true,
            },
        },
    components: {
     // HeaderComponent,
      DayComponent,
      FooterComponent
    }
  
  }
  </script>
