<template>
  <div class="about">
    <h1>Unser Team</h1>

    <section>
      <div class="container ">
          <div class="text-center mb-5">
              <h5>... das sind wir </h5>

          </div>
  
          <div class="row">
              <div class="col-sm-6 col-lg-4 mb-1-9 mb-lg-0">
  
              <div class="team-style1 hoverstyle1">
                      <div class="team-img">
                          <img src="../assets/img/orsi2022.png" alt="pic orsi" style="width:333px;height:500px">
                      </div>
                      <div><center><br>
                        <!-- <a class="icon px-3" href="https://www.linkedin.com/in/orsolyaveres/" target="_blank"><font-awesome-icon :icon="['fab', 'linkedin']" size="2x" style="color:rgb(102, 102, 51)"/></a> -->
                        <a class="icon px-3" href="https://www.instagram.com/orsi_ov/" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']" size="2x" style="color:rgb(102, 102, 51)"/></a>
                      </center>
                    </div>
                      <div class="team-info">
                          <h6 class="h5">Orsolya Veres</h6>
                          <small>Graphics & Illustrations</small>
                      </div>
                      <a href="https://www.linkedin.com/in/orsolyaveres/" class="p-2 fa-lg tw-ic" target="_blank">
                          <i class="fa fa-linkedin" style="color:rgb(102, 102, 51) "></i>
                      </a>
  
                      <a href="https://www.instagram.com/orsi_ov/" target="_blank" class="p-2 fa-lg dribbble-ic">
                          <i class="fa fa-instagram" style="color:rgb(102, 102, 51)"></i>
                      </a>
                  </div>
  
  
              </div>
  
              <div class="col-sm-6 col-lg-4">
  
  
              <div class="team-style1 hoverstyle1">
                      <div class="team-img">
                          <!-- <img src="./img/manu.png" style="width:256px;height:384px" alt="pic manu" > -->
                            <img src="../assets/img/manu2022.png" style="width:333px;height:500px" alt="pic manu" >
                      </div>
                      <div><center><br>
                        <!-- <a class="icon px-3" href="https://www.linkedin.com/in/manuela-thamer-0461231b8/" target="_blank"><font-awesome-icon :icon="['fab', 'linkedin']" size="2x" style="color:rgb(102, 102, 51)"/></a> -->
                        <a class="icon px-3" href="https://www.instagram.com/manoviews" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']" size="2x" style="color:rgb(102, 102, 51)"/></a>
                      </center>
                    </div>
                      <div class="team-info">
  
                          <h6 class="h5">Manuela Thamer</h6>
                          <small>Front- & Backend-Coding</small>
                      </div>
                      <div>
                        <a class="p-2 fa-lg tw-ic" href="https://www.linkedin.com/in/manuela-thamer-0461231b8/">
                            <i class="fa fa-linkedin" style="color:rgb(102, 102, 51) "> </i>
                        </a>
  
                        <a class="p-2 fa-lg dribbble-ic" href="https://www.instagram.com/manoviews/" target="_blank">
                            <i class="fa fa-instagram" style="color:rgb(102, 102, 51)"> </i>
                        </a>
                    </div>
                  </div>
  
  
              </div>
  
              <div class="col-sm-6 col-lg-4 mb-1-9 mb-lg-0">
  
                  <div class="team-style1 hoverstyle1">
                      <div class="team-img">
                          <img src="../assets/img/slava2022.png" alt="pic slavi" style="width:333px;height:500px">
                      </div>
                      <div><center><br>
                        <!-- <a class="icon px-3" href="https://www.facebook.com/ormanova" target="_blank"><font-awesome-icon :icon="['fab', 'facebook']" size="2x" style="color:rgb(102, 102, 51)"/></a> -->
                        <a class="icon px-3" href="https://www.instagram.com/scroepi" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']" size="2x" style="color:rgb(102, 102, 51)"/></a>
                      </center>
                    </div>
                      <div class="team-info">
                          <h6 class="h5">Slava Ormanova</h6>
                          <small>Digital Image & Video Processing</small>
                      </div>
                      <div>
                      <a class="p-2 fa-lg tw-ic" href="https://www.facebook.com/ormanova">
                          <i class="fa fa-facebook-f" style="color:rgb(102, 102, 51) "> </i>
                      </a>
                      </div>
                          </div>
  
              </div>
  
  
          </div>

          <div class="row">

            <div class="col-sm-6 col-lg-4 mb-1-9 mb-lg-0">
                <div class="team-style1 hoverstyle1">
                    <div class="team-img">
                            <!-- <img src="./img/manu.png" style="width:256px;height:384px" alt="pic manu" > -->
                            <img src="../assets/img/liz_1.png" style="width:333px;height:500px" alt="pic manu" >
                    </div>
                    <div><center><br>
                        <!-- <a class="icon px-3" href="https://www.facebook.com/ormanova" target="_blank"><font-awesome-icon :icon="['fab', 'facebook']" size="2x" style="color:rgb(102, 102, 51)"/></a> -->
                        <a class="icon px-3" href="https://www.instagram.com/strichpunk" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']" size="2x" style="color:rgb(102, 102, 51)"/></a>
                        </center>
                    </div>
                    <div class="team-info">

                        <h6 class="h5">Elisabeth Engl</h6>
                        <small>SEO</small>
                    </div>
                    <div>
                        <a class="p-2 fa-lg tw-ic" href="https://www.linkedin.com/in/manuela-thamer-0461231b8/">
                            <i class="fa fa-linkedin" style="color:rgb(102, 102, 51) "> </i>
                        </a>

                        <a class="p-2 fa-lg dribbble-ic" href="https://www.instagram.com/manoviews/" target="_blank">
                            <i class="fa fa-instagram" style="color:rgb(102, 102, 51)"> </i>
                        </a>
                    </div>
                </div>


            </div>

            <div class="col-sm-6 col-lg-4">

                <div class="team-style1 hoverstyle1">
                    <div class="team-img">
                        <img src="../assets/img/babsi.png" alt="pic babsi" style="width:333px;height:500px">
                    </div>
                    <div><center><br>
                        <!-- <a class="icon px-3" href="https://www.facebook.com/ormanova" target="_blank"><font-awesome-icon :icon="['fab', 'facebook']" size="2x" style="color:rgb(102, 102, 51)"/></a> -->
                        <a class="icon px-3" href="https://www.instagram.com/babra_ann" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']" size="2x" style="color:rgb(102, 102, 51)"/></a>
                      </center>
                    </div>
                    <div class="team-info">
                        <h6 class="h5">Barbara Lehner</h6>
                        <small>Social Media</small>
                    </div>
                    <a href="https://www.linkedin.com/in/barbaralehner/" class="p-2 fa-lg tw-ic" target="_blank">
                        <i class="fa fa-linkedin" style="color:rgb(102, 102, 51) "></i>
                    </a>

                    <a href="https://www.instagram.com/barbra_ann/" target="_blank" class="p-2 fa-lg dribbble-ic">
                        <i class="fa fa-instagram" style="color:rgb(102, 102, 51)"></i>
                    </a>
                </div>

            </div>

            <div class="col-sm-6 col-lg-4 mb-1-9 mb-lg-0">

                <div class="team-style1 hoverstyle1">
                    <div class="team-img">
                        <img src="../assets/img/emina.jpeg" alt="pic emi" style="width:333px;height:500px">
                    </div>
                    <div><center><br>
                        <!-- <a class="icon px-3" href="https://www.facebook.com/ormanova" target="_blank"><font-awesome-icon :icon="['fab', 'facebook']" size="2x" style="color:rgb(102, 102, 51)"/></a> -->
                        <a class="icon px-3" href="https://www.instagram.com/emi_holy" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']" size="2x" style="color:rgb(102, 102, 51)"/></a>
                      </center>
                    </div>
                    <div class="team-info">
                        <h6 class="h5">Emina Haligali</h6>
                        <small>Yoga Instructor</small>
                    </div>
                    <div>
                    <a class="p-2 fa-lg tw-ic" href="https://www.facebook.com/ormanova">
                        <i class="fa fa-facebook-f" style="color:rgb(102, 102, 51) "> </i>
                    </a>
                    </div>
                </div>

            </div>


        </div>



      </div>
  </section>
  <footer-component></footer-component>
  </div>

</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
export default {
  name: 'Hanno-Advent-Team',
  components: {
    FooterComponent
  }
}
</script>

<style scoped>

font-family: "Fahkwang", sans-serif, -apple-system, BlinkMacSystemFont,
"Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
sans-serif;
margin: 0;

h1{
      color: rgb(153, 0, 0);
}

h2{
      color: rgb(153, 0, 0);
}

ul {
  /* background-color: rgb(102, 102, 51) !important; */
  background-color: black;
      color: rgb(153, 0, 0);
  font-weight: bold;
}

li a {
      color: rgb(153, 0, 0);
  font-weight: bold;
}

.icon{
      color: rgb(153, 0, 0);
  font-weight: bold;
}

</style>